import {Box, CircularProgress, Modal, Typography} from "@mui/material";
import React from "react";

const FetchLoading = () => (
    <Box>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem"}}>
            <CircularProgress />
            <Typography component="h1" sx={{fontSize: "1.5rem"}}>Загрузка данных</Typography>

        </Box>
    </Box>
)

export default FetchLoading;