// eslint-disable-next-line import/prefer-default-export
export const  style_position_img = {
    "Большой": {
        top: "582px",
        left: "259px",
        width: "1000px",
        height: "1000px"
    },
    "Средний": {
        top: "727px",
        left: "323px",
        width: "750px",
        height: "750px"
    },
    "Маленький": {
        top: "908px",
        left: "403px",
        width: "500px",
        height: "500px"
    },
}