const  style_position_sale_label= {
    "0": {
        top: 298,
        left: 378,
    },
    "1": {
        top: 298,
        left: 842,
    },
    "2": {
        top: 665,
        left: 378,
    },
    "3": {
        top: 665,
        left: 842
    },
}

const  style_position_description= {
    "0": {
        top: 413,
        left: 103,
    },
    "1": {
        top: 413,
        left: 567,
    },
    "2": {
        top: 780,
        left: 103,
    },
    "3": {
        top: 780,
        left: 567,
    },
}

export {style_position_description, style_position_sale_label}