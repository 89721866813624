import React, {FC} from "react";
import {Box, Typography, Button} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const ResultModal: FC<any> = ({link, template, len, resolution, setResultModal}) => {
    const handleOpenFolder = () => {
        window.open(link, "_blank");
    };
    const handleCloseModal = () => {
        setResultModal(false);
    };
    return (
        <Box sx={{
            margin: "120px 12px", width: "480px", padding: "16px", backgroundColor: "white", borderRadius: "24px",
            display: "flex", flexDirection: "column", gap: "24px", alignItems: "center", position: "relative"
        }}>
            <Box sx={{position: "absolute", top: 16, right: 16,}}>
                <Button onClick={handleCloseModal}>
                    <CloseIcon />
                </Button>
            </Box>

            <Typography sx={{fontSize: "24px", fontWeight: 600}}>Баннеры созданы успешно</Typography>
            <CheckCircleOutlineIcon sx={{fontSize: "144px", color: "green"}}/>
            <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>

                <Typography sx={{fontSize: "16px", fontWeight: 500}}>
                    {`Создано ${len} шт. c разрешением ${resolution}`}
                </Typography>

            </Box>

            <Button
                onClick={handleOpenFolder}
                sx={{
                    margin: "auto 0 0 0",
                    textTransform: "none",
                    padding: "12px 24px",
                    backgroundColor: "orange",
                    borderRadius: "8px",
                    color: "white",
                }}>
                Открыть папку
            </Button>
        </Box>
    )
}

export default ResultModal;