import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import "./style.css";
import {ProductType} from "../../../../Pages/Main/type";

const SaleBlock1080_1920: FC<{price: string, discounted_price: string}> = ({price, discounted_price}) => (
    <Box sx={{
        minWidth: "434px",
        minHeight: "259px",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FCE300",
        zIndex: 1
    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "23px",
        }}>
            <Box>
                <Typography sx={{fontSize: "42px", lineHeight: "39px" ,fontWeight: 700}}>баға</Typography>
                <Typography sx={{fontSize: "42px", lineHeight: "39px" ,fontWeight: 700}}>цена:</Typography>
            </Box>
            <Box sx={{
                width: "254px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
            }}>
                <Typography sx={{fontSize: "81px", lineHeight: "101px" ,fontWeight: 700}}>{price}₸</Typography>
                <Box sx={{
                    width: "100%",
                    height: "5px",
                    backgroundColor: "#F5146E",
                    position: "absolute",
                    transform: "rotate(168deg)"
                }}/>
            </Box>
        </Box>
        <Typography sx={{textAlign: "center" ,fontSize: "170px", lineHeight: "149px" ,fontWeight: 700}}>{discounted_price}₸</Typography>
    </Box>
)


export default SaleBlock1080_1920;