import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import YellowFigureImage from "../../../../assets/images/yellow-back.png";
import TripleSaleBlock1080_1920 from "./TripleSaleBlock1080_1920";
import {style_position_img} from "./style_position_img_triple_1080_1920";
import {
    style_position_description,
    style_position_sale_label
} from "./style_position_triple_1080_1920";

const Triple1080_1920: FC<any> = ({data})=> (
    <>
        {
            data.map( (el: any, i: number) => (
                <Box>
                    {/* ГЛАВНАЯ КАРТИНКА */}
                    <Box
                        sx={{
                            transform: `rotate(${el.image_rotation}deg)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            position: "absolute",
                            //@ts-ignore
                            left: style_position_img[`${i}`][el.image_size]?.left,
                            //@ts-ignore
                            top: style_position_img[`${i}`][el.image_size]?.top,
                            //@ts-ignore
                            width: style_position_img[`${i}`][el.image_size]?.width,
                            //@ts-ignore
                            height: style_position_img[`${i}`][el.image_size]?.height
                        }}
                        style={{ backgroundImage: `url(${el.image})` }}
                    />
                    {/* ПРОДУКТ - СКИДКА */}
                    <Box sx={{
                        mb: "3rem",
                        alignSelf: "flex-end",
                        zIndex: 1,
                        //@ts-ignore
                        top: style_position_sale_label[`${i}`].top,
                        //@ts-ignore
                        left: style_position_sale_label[`${i}`].left,
                        position: "absolute",
                        width: "207px",
                        height: "144px",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <img
                            src={YellowFigureImage}
                            style={{
                                zIndex: 0,
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                transform: "rotate(180deg)"
                            }}
                            alt="yellow background"
                        />
                        <Typography
                            sx={{
                                position: "relative",
                                fontSize: "84px",
                                lineHeight: "157px",
                                color: "rgba(228, 0, 111, 1)",
                                fontWeight: 900
                            }}
                        >
                            {el.sale.slice(0, el.sale.length - 1)}
                            <span style={{fontSize: "48px"}}>%</span>
                        </Typography>
                    </Box>
                    {/* ОПИСАНИЕ + ЦЕННИК */}
                    <Box sx={{
                        width: "min-content",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        position: "absolute",
                        //@ts-ignore
                        top: style_position_description[`${i}`].top,
                        //@ts-ignore
                        left: style_position_description[`${i}`].left,
                        zIndex: 1
                    }}>
                        <TripleSaleBlock1080_1920 price={el.price} discounted_price={el.discounted_price}/>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px"
                        }}>
                            { el.title.split("\n").map((itemTitle: any, index: number) => {
                                if (itemTitle === "" || itemTitle === " ") {
                                    return  null
                                }
                                return (
                                    <Typography key={index} sx={{
                                        maxWidth: 251,
                                        color: "#FFF",
                                        fontSize: `${el.font_size || 20}px`,
                                        lineHeight: `${el.font_size || 20}px`,
                                        fontWeight: 700,
                                        textAlign: "start"
                                    }}
                                    >
                                        {itemTitle}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            ))
        }
    </>
)


export default  Triple1080_1920