// eslint-disable-next-line import/prefer-default-export
export const  style_position_img = {
    "Большой": {
        top: "19px",
        right: "15px",
        width: "743px",
        height: "743px"
    },
    "Средний": {
        top: "73px",
        right: "66px",
        width: "600px",
        height: "600px"
    },
    "Маленький": {
        top: "112px",
        right: "130px",
        width: "500px",
        height: "500px"
    },
}