import React, {FC, forwardRef} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import getTemplateComponent from "../../services/getTempleteComponent";


interface BannerData {
    order: number,
    dates: string,
    image: string,
    mass: string,
    price: string,
    sale: string,
    title: string,
    imageSize?: string,
    type?: string,
    imageRotation?: string
}

const ImageList: FC<any> = forwardRef((props: any, ref) => {

    const {
        template,
        resolution,
        data,
        handleModalOpen,
        handleChangeData,
    } = props;

    const getTitleProductTypography = (text: any, index: any) => (
        <Typography
            key={ index || null}
            sx={{
                maxWidth: "calc(1080px * 0.1666)",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                lineHeight: "16px",
                marginTop: "4px"
            }}
        >
            {data ? `${index + 1}. ${text.split("\n")[2] }` : null}
        </Typography>
    )
    const getTitleProduct = (dataProduct: any) => {
        if(Array.isArray(dataProduct)){
            return  dataProduct.map((el, i) => ( getTitleProductTypography(el.title, i)))
        }
        return getTitleProductTypography(dataProduct.title, 0)
    }


    return (
        <Box ref={ref} 
            sx={{ 
                display: "flex", 
                flexDirection: "row", 
                flexWrap: "wrap", 
                gap: "14px", 
                marginBottom: "96px" 
            }}
        >
            { 
                data[template].map((itemData: any, index: number) => {

                    const TemplateBannerComponent =  getTemplateComponent({template, resolution, itemData,})

                    return(
                        <Box key={`${index}_${template}`}>
                            <Box
                                sx={{
                                    width: `${resolution.split("x")[0] / 6}px`,
                                    height: `${resolution.split("x")[1] / 6}px`,
                                    // minWidth: "calc(1080*0.1666)px",
                                    borderRadius: "10px",
                                    overflow: "hidden",
                                    "&:hover": {
                                        boxShadow: "inset 0 0 0 1px #000, 0 0 4px #000",
                                    },
                                }}
                            >
                                <Box
                                    data-element={itemData.order ? itemData.order : itemData[0].order}
                                    sx={{
                                        width: `${resolution.split("x")[0] / 6}px`,
                                        height: `${resolution.split("x")[1] / 6}px`,
                                        transform: "scale(0.1666)",
                                        transformOrigin: "top left",
                                        display: "inline-block",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleModalOpen({open: true, resolution, itemData})}
                                >
                                    {/* ТИП ТЕМПЛЙТА, С РАСПОЛОЖЕНИЕМ ЕЛЕМЕНТОВ  НА БАННЕРЕ */}
                                    <TemplateBannerComponent availableData={{template, resolution, itemData,}}/>

                                </Box>
                            </Box>
                            {
                                getTitleProduct(itemData)
                            }
                            {
                                template === "ordinary"
                                    ? <>
                                        {/* ВЫБОР РАЗМЕРА КАРТИНКИ */}
                                        <Box sx={{mt: ".75rem", width: 179}}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Размер картинки</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={itemData.image_size || "Средний"}
                                                    label="Размер картинки"
                                                    onChange={e => handleChangeData("ordinary", itemData.order, "image_size", e.target.value)}
                                                >
                                                    {["Большой", "Средний", "Маленький"].map((item, index) => (
                                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {/* ВЫБОР ТИПА ШАБЛОНА */}
                                        <Box sx={{mt: ".75rem", width: 179}}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={itemData.image_type || "Стандарт"}
                                                    label="Тип"
                                                    onChange={e => handleChangeData("ordinary", itemData.order, "image_type", e.target.value)}
                                                >
                                                    {["Стандарт", "Стандарт с отступом", "Стандарт с отступом без фона", "Стандарт без фона"].map((itemData, index) => (
                                                        <MenuItem key={index} value={itemData}>{itemData}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {/* ПОВОРОТ КАРТИНКИ */}
                                        <Box sx={{mt: ".75rem", width: 179}}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Поворот картинки</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={itemData.image_rotation || "0"}
                                                    label="Поворот картинки"
                                                    onChange={e => handleChangeData("ordinary", itemData.order, "image_rotation", e.target.value)}
                                                >
                                                    {
                                                        ["0", "30", "45", "60", "90", "120", "135", "150", "165", "180", "210", "225", "240", "270", "300", "315"]
                                                            .map((itemData, index) => (
                                                                <MenuItem key={index} value={itemData}>{itemData}</MenuItem>
                                                            ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {/* РАЗМЕР ШРИФТА */}
                                        <Box sx={{mt: ".75rem", width: 179}}>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    size="small"
                                                    id="outlined-number"
                                                    label="Размер шрифта"
                                                    type="number"
                                                    defaultValue="20"
                                                    value={itemData.font_size}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={e => handleChangeData("ordinary", itemData.order, "font_size", e.target.value)}
                                                />
                                            </FormControl>
                                        </Box>
                                    </>
                                    : null
                            }
                        </Box>
                    )
                })
            }
        </Box>
    );
});

export default ImageList;
