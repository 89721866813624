import React from "react";
import OrdinaryBanner from "../Components/Templates/Ordinary/OrdinaryProduct";
import DoubleBanner from "../Components/Templates/Double/DoubleProduct";
import TripleBanner from "../Components/Templates/Triple/TripleProduct";
import QuadrupleBanner from "../Components/Templates/Quadruple/QuadrupleProduct";

export default function  getTemplateComponent(availableData :any) {
    const {template} = availableData
    const BANNERS_TEMPLATES = {
        "ordinary": OrdinaryBanner,
        "double": DoubleBanner,
        "triple": TripleBanner,
        "quadruple": QuadrupleBanner,
    }

    // @ts-ignore
    return BANNERS_TEMPLATES[template]
}