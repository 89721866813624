import {Box, Typography} from "@mui/material";
import React, {FC} from "react";
import SaleBlock1080_1080 from "./SaleBlock1080_1080";
import YellowFigureImage from "../../../../assets/images/yellow-back.png";
import WhiteFigureImage from "../../../../assets/images/white-back.png";
import {style_position_img} from "./style_position_img_ordinary_1080_1080"
import {type_style_content_banner} from "./type_style_content_banner_ordinary_1080_1080"
import "../styles.css";

const Ordinary1080_1080: FC<any> = ({data}) => {
    const {
        image_type,
        title,
        sale,
        image_size,
        image,
        image_rotation,
        font_size,
    } = data
    return (
        <Box sx={{
            position: "relative",
            height: "100%"
        }}>
            <Box
                sx={{
                    zIndex: 1,
                    position: "absolute",
                    //@ts-ignore
                    top: type_style_content_banner[image_type]?.info.top,
                    //@ts-ignore
                    left: type_style_content_banner[image_type]?.info.left,
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                {/* ЛЭЙБЕЛ ПРОЦЕНТ СКИДКИ */}
                <Box
                    sx={{
                        mb: "3rem",
                        alignSelf: "flex-end",
                        position: "relative",
                        width: "276px",
                        height: "191px",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <img
                        src={YellowFigureImage}
                        style={{
                            zIndex: 0,
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                        alt="yellow background"
                    />
                    <Typography
                        sx={{
                            position: "relative",
                            fontSize: "126px",
                            color: "rgba(228, 0, 111, 1)",
                            fontWeight: 900
                        }}
                    >
                        {
                            sale.slice(0, sale.length - 1)
                        }
                        <span style={{fontSize: "52px"}}>%</span>
                    </Typography>
                </Box>

                <SaleBlock1080_1080 data={data} />

                {/* ОПИСАНИЕ ТОВАРА */}
                <Box
                    sx={{
                        mt: "1rem",
                        maxWidth: "326px"
                    }}
                >
                    {
                        title.split("\n").map((item: any, index: number) => {
                            if (item === "" || item === " ") {
                                return <Box sx={{mb: ".675rem"}} />
                            }
                            return (
                                <Typography
                                    key={index}
                                    sx={{
                                        //@ts-ignore
                                        color: type_style_content_banner[image_type]?.infoDescription.color,
                                        fontSize: `${font_size || 20}px`,
                                        lineHeight: `${font_size || 20}px !important`
                                    }}
                                >
                                    {item}
                                </Typography>
                            )
                        })
                    }
                </Box>
            </Box>

            {/* ГЛАВНАЯ КАРТИНКА */}
            <Box
                sx={{
                    position: "absolute",
                    //@ts-ignore
                    top: type_style_content_banner[image_type]?.whiteBack.top,
                    //@ts-ignore
                    right: type_style_content_banner[image_type]?.whiteBack.right,
                    zIndex: 0,
                    //@ts-ignore
                    display: type_style_content_banner[image_type]?.whiteBack.display,
                    //@ts-ignore
                    transform: type_style_content_banner[image_type]?.whiteBack.transform
                }}
            >
                <img
                    src={WhiteFigureImage}
                    style={{
                        //@ts-ignore
                        width: type_style_content_banner[image_type]?.whiteBack.width,
                        //@ts-ignore
                        height: type_style_content_banner[image_type]?.whiteBack.height
                    }}
                    alt="white back"
                />
            </Box>
            <Box
                sx={{
                    transform: `rotate(${image_rotation}deg)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    position: "absolute",
                    //@ts-ignore
                    right: style_position_img[image_size]?.right,
                    //@ts-ignore
                    top: style_position_img[image_size]?.top,
                    //@ts-ignore
                    width: style_position_img[image_size]?.width,
                    //@ts-ignore
                    height: style_position_img[image_size]?.height
                }}
                style={{ backgroundImage: `url(${image})` }}
            />
        </Box>
    )
}

export default Ordinary1080_1080;