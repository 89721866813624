import React, {FC} from "react";
import BannerTemplate from "../BannerTemplate";
import {getBannerBySize} from "../../../services/getBannerBySize";

const DoubleBanner: FC<any> = ({availableData}) => {
    const { resolution, template, itemData } = availableData
    const BannerComponent = getBannerBySize(resolution, template);
    return(
        <BannerTemplate availableData={availableData}>
            <BannerComponent data={itemData}/>
        </BannerTemplate>
    )}


export default DoubleBanner;