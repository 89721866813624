// eslint-disable-next-line import/prefer-default-export
export const  style_position_img = {
    "0": {
        "Большой": {
            top: "373px",
            left: "395px",
            width: "450px",
            height: "450px"
        },
        "Средний": {
            top: "373px",
            left: "395px",
            width: "400px",
            height: "400px"
        },
        "Маленький": {
            top: "392px",
            left: "423px",
            width: "350px",
            height: "350px"
        },
    },
    "1": {
        "Большой": {
            top: "839px",
            left: "395px",
            width: "450px",
            height: "450px"
        },
        "Средний": {
            top: "839px",
            left: "395px",
            width: "400px",
            height: "400px"
        },
        "Маленький": {
            top: "857px",
            left: "423px",
            width: "350px",
            height: "350px"
        },
    },
    "2": {
        "Большой": {
            top: "1305px",
            left: "395px",
            width: "450px",
            height: "450px"
        },
        "Средний": {
            top: "1305px",
            left: "395px",
            width: "400px",
            height: "400px"
        },
        "Маленький": {
            top: "1323px",
            left: "423px",
            width: "350px",
            height: "350px"
        },
    },
}