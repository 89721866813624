import React from "react";
import Ordinary1080_1920 from "../Components/Templates/Ordinary/Ordinary1080_1920/Ordinary1080_1920";
import Ordinary1080_1080 from "../Components/Templates/Ordinary/Ordinary1080_1080/Ordinary1080_1080";
import Double1080_1920 from "../Components/Templates/Double/Double1080_1920/Double1080_1920";
import Double1080_1080 from "../Components/Templates/Double/Double1080_1080/Double1080_1080";
import Triple1080_1080 from "../Components/Templates/Triple/Triple1080_1080/Triple1080_1080";
import Triple1080_1920 from "../Components/Templates/Triple/Triple1080_1920/Triple1080_1920";
import Quadruple1080_1080 from "../Components/Templates/Quadruple/Quadruple1080_1080/Quadruple1080_1080";

interface BannerComponents {
    [key: string]: React.ComponentType<any>;
}
// КОМПОНЕНТЫ ПО ВЫБОРУ ШАБЛОНА ДЛЯ СОЗДАНИЯ КАРТИНОК
const BANNER_COMPONENTS: BannerComponents = {
    "Ordinary1080_1920": Ordinary1080_1920,
    "Ordinary1080_1080": Ordinary1080_1080,
    "Double1080_1920": Double1080_1920,
    "Double1080_1080": Double1080_1080,
    "Triple1080_1080": Triple1080_1080,
    "Triple1080_1920": Triple1080_1920,
    "Quadruple1080_1080": Quadruple1080_1080,

};

const typeTemplates = {
    ordinary: "Ordinary",
    double: "Double",
    triple: "Triple",
    quadruple: "Quadruple"
}

// eslint-disable-next-line import/prefer-default-export
export const getBannerBySize = (resolution = "", template = "ordinary") => {
    // ПО ДАННЫМ НА ПРИХОДЕ ПАРСИМ КЛЮЧ ОБЬЕКТА И ДОСТАЕМ КОМПОНЕНТ
    //@ts-ignore
    const componentName = `${typeTemplates[template]}${resolution.replace("x", "_")}`;
    return BANNER_COMPONENTS[componentName];
};