import React from "react";
import {Box, Typography} from "@mui/material";
import {ReactComponent as TDSLogo} from "../../assets/images/tds-logo.svg";

const Header = () => (
    <Box sx={{backgroundColor: "gray", margin: "auto 0 ", height: "64px", top: 0,width: "100%" , display: "flex", justifyContent: "center", alignItems: "center", zIndex: "10" }}>
        <Box sx={{ maxWidth: "1220px", width: "100%", display: "flex", margin: "auto 0 ", justifySelf: "flex-start",alignItems: "center", gap : 24}}>
            <TDSLogo />
            <Typography color="white" sx={{margin: "auto 0", fontSize:"24px" }}>
                TDS Media Instruments
            </Typography>
        </Box>
    </Box>
);

export default Header;
