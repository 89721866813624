import {Box, Typography} from "@mui/material";
import React, {FC} from "react";
import SaleBlock1080_1920 from "./SaleBlock1080_1920";
import "./style.css"
import WhiteFigureImage from "../../../../assets/images/white-back.png";
import {style_position_img} from "./style_position_img_ordinary_1080_1920"
import {type_style_content_banner} from "./type_style_content_banner_ordinary_1080_1920"
import YellowFigureImage from "../../../../assets/images/yellow-back.png";

const Ordinary1080_1920: FC<any> = ({data}) => {
    const {
        image_type,
        title,
        sale,
        image_size,
        image,
        image_rotation,
        discounted_price,
        price,
        font_size
    } = data

    return (
        <>
            <Box sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                {/*  МЕДИАТОР ФОН БЕЛЫЙ */}
                <Box
                    sx={{
                        position: "absolute",
                        //@ts-ignore
                        top: type_style_content_banner[image_type]?.whiteBack.top,
                        //@ts-ignore
                        right: type_style_content_banner[image_type]?.whiteBack.right,
                        zIndex: 0,
                        //@ts-ignore
                        display: type_style_content_banner[image_type]?.whiteBack.display,
                        //@ts-ignore
                        transform: type_style_content_banner[image_type]?.whiteBack.transform
                    }}
                >
                    <img
                        src={WhiteFigureImage}
                        style={{
                            //@ts-ignore
                            width: type_style_content_banner[image_type]?.whiteBack.width,
                            //@ts-ignore
                            height: type_style_content_banner[image_type]?.whiteBack.height}}
                        alt="white back"
                    />
                </Box>
            </Box>

            {/* БЛОК С ИНФОРМАЦИЕЙ */}
            <Box sx={{
                zIndex: 1,
                position: "absolute",
                //@ts-ignore
                top: type_style_content_banner[image_type]?.info.top,
                //@ts-ignore
                left: type_style_content_banner[image_type]?.info.left,
                display: "flex",
                flexDirection: "column"
            }}>

                {/* СКИДКА */}
                <Box sx={{
                    mb: "3rem",
                    alignSelf: "flex-end",
                    position: "relative",
                    width: "380px",
                    height: "262px",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <img
                        src={YellowFigureImage}
                        style={{
                            zIndex: 0,
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                        alt="yellow background"
                    />
                    <Typography
                        sx={{
                            position: "relative",
                            fontSize: "173px",
                            lineHeight: "229px",
                            color: "rgba(228, 0, 111, 1)",
                            fontWeight: 900
                        }}
                    >
                        {
                            sale.slice(0, sale.length - 1)
                        }
                        <span style={{fontSize: "72px"}}>%</span>
                    </Typography>
                </Box>

                {/* БЛОК С ИНФО СО СКИДКОЙ */}
                <SaleBlock1080_1920 price={price} discounted_price={discounted_price} />

                {/* БЛОК С ОПИСАНИЕМ ТОВАРА */}
                <Box sx={{
                    mt: "1rem", maxWidth: "326px"
                }}>
                    { title.split("\n").map((item: any, index: number) => {
                        if (item === "" || item === " ") {
                            return <Box sx={{mb: ".675rem"}} />
                        }
                        return (
                            <Typography key={index} sx={{
                                //@ts-ignore
                                color: type_style_content_banner[image_type]?.infoDescription.color,
                                fontSize: `${font_size || 20}px`,
                                lineHeight: `${font_size}px !important`
                            }}
                            >
                                {item}
                            </Typography>
                        )
                    })}
                </Box>
            </Box>

            {/* ГЛАВНАЯ КАРТИНКА */}
            <Box
                sx={{
                    transform: `rotate(${image_rotation}deg)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    position: "absolute",
                    //@ts-ignore
                    left: style_position_img[image_size]?.left,
                    //@ts-ignore
                    top: style_position_img[image_size]?.top,
                    //@ts-ignore
                    width: style_position_img[image_size]?.width,
                    //@ts-ignore
                    height: style_position_img[image_size]?.height
                }}
                style={{ backgroundImage: `url(${image})` }}
            />
        </>
    )
}

export default Ordinary1080_1920;