import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import MagnumLogo from "../../assets/images/magnum_paida.png";
import "./banner-styles.css";

// eslint-disable-next-line react/require-default-props
const BannerTemplate: FC<any> = ({availableData, children}) => {
    const {resolution, itemData} = availableData
    const [width, height] = resolution.split("x");
    let date;

    if(itemData.dates){
        date = itemData.dates
    } else if(Array.isArray(itemData)) {
        date = itemData[0].dates
    } else {
        date = "Даты нет в данных!-Даты нет в данных!"
    }

    date = date.split("-")

    return (
        <div className="banner-container" style={{
            width: `${width}px`,
            height: `${height}px`,
        }}>
            <div className="banner-header">
                {/* ЛОГОТИП С ДАТОЙ */}
                <Box sx={{mt: ".5rem"}}>
                    <img src={MagnumLogo} alt="logo" />
                </Box>
                <span className="banner-date">
                    {
                        date[0]
                    }
                    <br/>
                    {
                        date[1]
                    }
                </span>
            </div>
            {/* КАРТИНКА ТОВАРА И ВСЕ ОСТАЛЬНОЕ */}
            {children}
            <Box sx={{
                position: "absolute",
                bottom: 12,
                color: "#FFF",
                width: "100%",
                margin: "0 auto",
                textAlign: "center"
            }}>
                <Typography>{"Асығыңыз, тауар саны шектеулі Тауардың фотосы кейде өзгеше болуы мүмкін.".toUpperCase()}</Typography>
                <Typography>{"Спешите, количество товара ограничено. Фото товара иногда может отличаться.".toUpperCase()}</Typography>
            </Box>
        </div>
    )
}

export default BannerTemplate;
