import React, {useState, useRef, useEffect,} from "react";
import {Box, Modal,} from "@mui/material";
import BannerModal from "../../Components/BannerModal/BannerModal";
import TemplateSetting from "../../Components/FormSettings/TemplateSetting";
import ResolutionSetting from "../../Components/FormSettings/ResolutionSetting";
import ImageList from "../../Components/ImageList/ImageList";
import {BannerModalType} from "./type";
import {initialBannerModal} from "./constants";
import SendLoading from "../../Components/Loading/SendLoading";
import ResultModal from "../../Components/Modals/ResultModal/ResultModal";
import ErrorModal from "../../Components/Modals/ResultModal/ErrorModal";
import "./styles.css";
import CustomButton from "../../Components/UI/CustomButton/CustomButton";
import FetchLoading from "../../Components/Loading/FetchLoading";
import Header from "../../Components/Header/Header";
import {getBannerBySize} from "../../services/getBannerBySize";
import {fetchData, sendBanners} from "../../services/api";

const Main = () => {
    const imagesRef = useRef<HTMLElement | null>(null);
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [resolution, setResolution] = useState("1080x1080");
    const [template, setTemplate] = useState("ordinary");
    const [imageBoard, setImageBoard] = useState(false);
    const [bannerModal, setBannerModal] = useState<BannerModalType>(initialBannerModal);
    const [link, setLink] = useState();
    const [resultModal, setResultModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false);
    const [width, height] = resolution.split("x");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        let newData
        (async ()=>{
            try {
                const bannerData = await fetchData();
                setData(bannerData.data);
            } catch (e) {
                setErrorMessage(`С загрузкой что-то пошло не так!, ${e}`)
                setErrorModal(true)
            }

        })()
    }, []);

    const handleChangeData = (type_group: any, order: number, key: string, value: string) => {
        setData(
            {
                ...data,
                [type_group]: data[type_group].map((el: any) => el.order === order ? {...el, [key]: value} : el)
            }
        );
    }

    const handleResolutionChange = (e: any) => {
        setResolution(e.target.value);
        setImageBoard(false);
    };
    const handleTemplateChange = (e: any) => {
        setTemplate(e.currentTarget.value);
        setImageBoard(false)
    };
    const handleGenerate = () => {
        if(getBannerBySize(resolution, template)){
            setImageBoard(true);
        } else {
            setErrorMessage("Такого шаблона нет!")
            setErrorModal(true)
        }

    }
    const handleModalOpen = (data: BannerModalType) => setBannerModal(data);
    const handleModalClose = () => setBannerModal(initialBannerModal);

    const handleSend = async () => {
        setLoading(true);
        await sendBanners(template, width, height, imagesRef, data, setLoading, setLink, setResultModal, setErrorModal, setErrorMessage);
    }
    if (loading) {
        return <SendLoading />
    }
    return (
        <>
            {/* ШАПКА APP */}
            <Header />
            <Box className="page-container">
                <Box sx={{padding: "12px"}}>
                </Box>
                <Box className="settings-block" >
                    {/* input radio ВЫБОР РАЗМЕРА */}
                    <ResolutionSetting resolution={resolution} handleResolutionChange={handleResolutionChange}/>
                    {/* КНОПКИ СГЕНЕРИРОВАТЬ ОТПРАВИТЬ */}
                    <Box sx={{display: "flex", flexDirection: "row", gap: "24px",}}>
                        <CustomButton onClick={handleGenerate} label="Сгенерировать"/>
                        <CustomButton onClick={() => { setLoading(true); handleSend(); }} label="Отправить"/>
                    </Box>
                </Box>
                {/* input radio с ТИПАМИ ШАБЛОНОВ  */}
                <Box sx={{display: "flex"}}>
                    <TemplateSetting resolution={template} handleResolutionChange={handleTemplateChange}/>
                </Box>
                {
                    Object.keys(data).length === 0 && <FetchLoading />
                }
                {/* ЛИСТ С ПОДГРУЖЕННЫМИ КАРТОЧКАМИ И ПОЛЯМИ ДЛЯ ИЗМЕНЕНИЙ */}
                {
                    imageBoard && <ImageList
                        handleChangeData={handleChangeData}
                        ref={imagesRef}
                        template={template}
                        resolution={resolution}
                        data={data}
                        handleModalOpen={handleModalOpen}
                    />
                }
                {/* МОДАЛКА С ГОТОВОЙ КАРТИНКОЙ */}
                <Modal
                    open={bannerModal.open}
                    onClose={handleModalClose}
                    // onClick={handleModalClose}
                    className="modal-content"
                >
                    <Box className="modal-banner">
                        <BannerModal
                            availableData={{
                                resolution: bannerModal.resolution,
                                itemData: bannerModal.itemData,
                                template
                            }}
                        />
                    </Box>
                </Modal>
                <Modal
                    className="modal-content"
                    open={resultModal}
                    onClose={()=>setResultModal(false)}
                >
                    <ResultModal
                        template={template}
                        //@ts-ignore
                        len={data[template] && data[template].length}
                        resolution={resolution}
                        link={link}
                        setResultModal={setResultModal} />
                </Modal>
                <Modal
                    className="modal-content"
                    open={errorModal}
                    onClose={()=>setErrorModal(false)}
                >
                    <ErrorModal setErrorModal={setErrorModal} errorMessage={errorMessage}/>
                </Modal>
            </Box>
        </>

    )
}

export default Main;

