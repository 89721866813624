import React from "react";
import "./App.css";
import {Routes, Route} from "react-router";
import Main from "../Pages/Main/Main";
import "../assets/fonts/CeraPro/CeraPro-Medium.woff";

const App = () => (
    <div className="App">
        <Routes>
            <Route path="/" element={<Main />} />
        </Routes>
    </div>
)

export default App;
