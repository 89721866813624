import {Box, CircularProgress, Modal, Typography} from "@mui/material";
import React from "react";

const SendLoading = () => (
    <Modal
        open
        sx={{display: "flex", alignItems: "center", justifyContent: "center", zIndex: "100", position: "absolute"}}
        BackdropProps={{
            sx: {
                opacity: ".25 !important"
            }
        }}
    >
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem"}}>
            <Typography component="h1" sx={{fontSize: "1.5rem"}}>Баннера создаются и сохраняются, это может занять некоторое время!</Typography>
            <CircularProgress />
        </Box>
    </Modal>
)

export default SendLoading;