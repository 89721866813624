import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

const TripleSaleBlock1080_1920: FC<{price: string, discounted_price: string}> = ({price, discounted_price}) => (
    <Box sx={{
        maxWidth: "fit-content",
        minWidth: "256px",
        minHeight: "116px",
        padding: "13px",
        display: "flex",
        flexDirection: "column",
        gap: "13px",
        backgroundColor: "#FCE300",
        zIndex: 1
    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "23px",
        }}>
            <Box>
                <Typography sx={{fontSize: "24px", lineHeight: "22px" ,fontWeight: 700}}>баға</Typography>
                <Typography sx={{fontSize: "24px", lineHeight: "22px"  ,fontWeight: 700}}>цена:</Typography>
            </Box>
            <Box sx={{
                width: "146px",
                height: "31px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
            }}>
                <Typography sx={{fontSize: "45px", lineHeight: "59px" ,fontWeight: 700}}>{price}₸</Typography>
                <Box sx={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#F5146E",
                    position: "absolute",
                    transform: "rotate(168deg)"
                }}/>
            </Box>
        </Box>
        <Typography sx={{ height: 65,textAlign: "center" ,fontSize: "91px", lineHeight: "71px" ,fontWeight: 700}}>{discounted_price}₸</Typography>
    </Box>
)


export default TripleSaleBlock1080_1920;