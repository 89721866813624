// eslint-disable-next-line import/prefer-default-export
export const  style_position_img = {
    "0": {
        "Большой": {
            top: "372px",
            left: "282px",
            width: "700px",
            height: "700px"
        },
        "Средний": {
            top: "372px",
            left: "303px",
            width: "600px",
            height: "600px"
        },
        "Маленький": {
            top: "416px",
            left: "345px",
            width: "500px",
            height: "500px"
        },
    },
    "1": {
        "Большой": {
            top: "1120px",
            left: "282px",
            width: "700px",
            height: "700px"
        },
        "Средний": {
            top: "1120px",
            left: "303px",
            width: "600px",
            height: "600px"
        },
        "Маленький": {
            top: "1186px",
            left: "345px",
            width: "500px",
            height: "500px"
        },
    },
}