import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import React, {FC} from "react";

const ResolutionSetting: FC<any> = ({resolution, handleResolutionChange}) => (
    <FormControl component="fieldset" style={{
        display: "flex",
        flexDirection: "row",
        padding: "16px",
        backgroundColor: "#f5f5f5", borderRadius: "16px",
        justifyContent: "center",alignItems: "center"
    }}>
        <Typography sx={{textAlign: "center", justifySelf:"center", marginRight: "24px"}}>Выберите разрешение: </Typography>
        <RadioGroup row aria-label="resolution" name="resolution" value={resolution} onChange={handleResolutionChange}>
            {/*<FormControlLabel value="1080x1350" control={<Radio />} label="1080x1350" />*/}
            <FormControlLabel value="1080x1920" control={<Radio />} label="1080x1920" />
            <FormControlLabel value="1080x1080" control={<Radio />} label="1080x1080" />
        </RadioGroup>
    </FormControl>
);

export default ResolutionSetting;