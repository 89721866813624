import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import "../styles.css";

const SaleBlock1080_1080: FC<any> = ({data}) => (
    <Box
        sx={{position: "relative", width: "fit-content", minHeight: "190px", background: "rgba(252, 227, 0, 1)", p: "15px 15px 15px 29px"}}
    >
        <Box sx={{mb: "1.5rem", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Box>
                <Typography className="sale-tag-text">
                    баға
                </Typography>
                <Typography className="sale-tag-text">
                    цена:
                </Typography>
            </Box>
            <Box sx={{position: "relative"}}>
                <Box>
                    <Typography className="price_text">
                        {data.price}₸
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box>
            <Typography className="discounted_price_text">
                {data.discounted_price}₸
            </Typography>
        </Box>
    </Box>
)

export default SaleBlock1080_1080;