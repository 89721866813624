import html2canvas from "html2canvas";
import axios from "axios";
import {BASE_URL} from "./constants";

export const sendBanners = async (template: any, width: any, height: any, imagesRef: any, data: any, setLoading: any, setLink: any, setResultModal: any, setErrorModal: any, setErrorMessage: any) => {
    try {
        setLoading(true);
        const bannerElements = (imagesRef.current as HTMLElement).querySelectorAll("[data-element]");
        const backendData = await Promise.all(data[template].map(async (item: any, index: number) => {
            (bannerElements[index] as any).style = "width: auto; height: auto; transform: scale(1)";
            const canvas = await html2canvas(bannerElements[index] as HTMLElement, {
                useCORS: true,
                scale: 2,
            })
            const imageBlob = canvas.toDataURL("image/png");
            return {
                order: !Array.isArray(item) ? item.order : item[0].order,
                image: imageBlob,
                template,
                width: Number(width),
                height: Number(height)
            }
        }));
        const response = await axios.post(`${BASE_URL}/api/docs/upload-image/mass_upload/`, backendData);
        if (response.data.date_folder_url) {
            setLink(response.data.date_folder_url);
        }
        setResultModal(true);
    } catch (error) {
        console.error("Error uploading images:", error);
        setErrorModal(true);
        // @ts-ignore
        setErrorMessage(error.message);
        // throw error;
    } finally {
        setLoading(false);
    }
};

export const fetchData =  () => (axios.get(`${BASE_URL}/api/docs/by_group/`))
