// eslint-disable-next-line import/prefer-default-export
export const type_style_content_banner = {
    "Стандарт": {
        whiteBack: {
            transform: "rotate(100deg)",
            right: "36px",
            top: "572px",
            width: "968px",
            height: "978px"
        },
        info: {
            left: "115px",
            top: "625px"
        },
        infoDescription: {
            color: "black"
        }
    },
    "Стандарт с отступом": {
        whiteBack: {
            transform: "rotate(144deg)",
            right: "36px",
            top: "572px",
            width: "850px",
            height: "914px"
        },
        info: {
            left: "118px",
            top: "829px"
        },
        infoDescription: {
            color: "white"
        }
    },
    "Стандарт с отступом без фона": {
        whiteBack: {
            display: "none",
            transform: "rotate(-100deg)"
        },
        info: {
            left: "118px",
            top: "829px"
        },
        infoDescription: {
            color: "white"
        }
    },
    "Стандарт без фона": {
        whiteBack: {
            display: "none",
            transform: "rotate(-100deg)"
        },
        info: {
            left: "115px",
            top: "625px"
        },
        infoDescription: {
            color: "white"
        }
    },
}