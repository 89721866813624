import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import React, {FC} from "react";

const TemplateSetting: FC<any> = ({ resolution, handleResolutionChange }) => (
    <FormControl component="fieldset" style={{
        display: "flex",
        flexDirection: "row",
        padding: "16px",
        backgroundColor: "#f5f5f5", borderRadius: "16px",
        justifyContent: "center", alignItems: "center"
    }}>
        <Typography sx={{textAlign: "center", justifySelf:"center", marginRight: "24px"}}>Выберите шаблон: </Typography>
        <RadioGroup row aria-label="resolution" name="resolution" value={resolution} onChange={handleResolutionChange}>
            <FormControlLabel value="ordinary" control={<Radio />} label="Ordinary" />
            <FormControlLabel value="double" control={<Radio />} label="Double" />
            <FormControlLabel value="triple" control={<Radio />} label="Triple" />
            <FormControlLabel value="quadruple" control={<Radio />} label="Quadruple" />
        </RadioGroup>
    </FormControl>
);

export default TemplateSetting;