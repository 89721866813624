import React, {FC} from "react";
import {Button} from "@mui/material";
import "../../../Pages/Main/styles.css";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

const cache = createCache({
    key: "css",
    prepend: true,
});

const CustomButton: FC<any> = ({label, onClick}) => 
    // @ts-ignore
    (
        <CacheProvider value={cache}>
            <Button className="generate-button" onClick={onClick}>
                {label}
            </Button>
        </CacheProvider>
    )


export default CustomButton;