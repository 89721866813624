const  style_position_sale_label= {
    "0": {
        top: 581,
        left: 105,
    },
    "1": {
        top: 572,
        left: 796,
    },

}

const  style_position_description= {
    "0": {
        top: 313,
        left: 378,
    },
    "1": {
        top: 762,
        left: 454,
    },
}

export {style_position_description, style_position_sale_label}