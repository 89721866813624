const  style_position_sale_label= {
    "0": {
        top: 448,
        left: 692,
    },
    "1": {
        top: 1196,
        left: 692,
    },

}

const  style_position_description= {
    "0": {
        top: 764,
        left: 159,
    },
    "1": {
        top: 1512,
        left: 159,
    },
}

export {style_position_description, style_position_sale_label}