// eslint-disable-next-line import/prefer-default-export
export const  style_position_img = {
    "0": {
        "Большой": {
            top: "260px",
            left: "68px",
            width: "500px",
            height: "500px"
        },
        "Средний": {
            top: "312px",
            left: "81px",
            width: "400px",
            height: "400px"
        },
        "Маленький": {
            top: "374px",
            left: "97px",
            width: "300px",
            height: "300px"
        },
    },
    "1": {
        "Большой": {
            top: "506px",
            left: "498px",
            width: "500px",
            height: "500px"
        },
        "Средний": {
            top: "556px",
            left: "548px",
            width: "400px",
            height: "400px"
        },
        "Маленький": {
            top: "667px",
            left: "657px",
            width: "300px",
            height: "300px"
        },
    },
}