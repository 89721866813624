import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

const TripleSaleBlock1080_1080: FC<{price: string, discounted_price: string}> = ({price, discounted_price}) => (
    <Box sx={{
        maxWidth: "fit-content",
        minWidth: "230px",
        minHeight: "116px",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        backgroundColor: "#FCE300",
        zIndex: 1
    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "23px",
        }}>
            <Box>
                <Typography sx={{fontSize: "19px", lineHeight: "17px" ,fontWeight: 700}}>баға</Typography>
                <Typography sx={{fontSize: "19px", lineHeight: "17px" ,fontWeight: 700}}>цена:</Typography>
            </Box>
            <Box sx={{
                width: "114px",
                height: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
            }}>
                <Typography sx={{fontSize: "36px", lineHeight: "45px" ,fontWeight: 700}}>{price}₸</Typography>
                <Box sx={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#F5146E",
                    position: "absolute",
                    transform: "rotate(168deg)"
                }}/>
            </Box>
        </Box>
        <Typography sx={{ height: 50,textAlign: "center" ,fontSize: "70px", lineHeight: "55px" ,fontWeight: 700}}>{discounted_price}₸</Typography>
    </Box>
)


export default TripleSaleBlock1080_1080;