// eslint-disable-next-line import/prefer-default-export
export const  style_position_img = {
    "0": {
        "Большой": {
            top: "278px",
            left: "200px",
            width: "350px",
            height: "350px"
        },
        "Средний": {
            top: "239px",
            left: "172px",
            width: "300px",
            height: "300px"
        },
        "Маленький": {
            top: "278px",
            left: "200px",
            width: "250px",
            height: "250px"
        },
    },
    "1": {
        "Большой": {
            top: "278px",
            left: "664px",
            width: "350px",
            height: "350px"
        },
        "Средний": {
            top: "312px",
            left: "709px",
            width: "300px",
            height: "300px"
        },
        "Маленький": {
            top: "330px",
            left: "724px",
            width: "250px",
            height: "250px"
        },
    },
    "2": {
        "Большой": {
            top: "645px",
            left: "413px",
            width: "350px",
            height: "350px"
        },
        "Средний": {
            top: "679px",
            left: "453px",
            width: "300px",
            height: "300px"
        },
        "Маленький": {
            top: "697px",
            left: "471px",
            width: "250px",
            height: "250px"
        },
    },
}