const  style_position_sale_label= {
    "0": {
        top: 391,
        left: 675,
    },
    "1": {
        top: 857,
        left: 675,
    },
    "2": {
        top: 1323,
        left: 675,
    },
}

const  style_position_description= {
    "0": {
        top: 546,
        left: 206,
    },
    "1": {
        top: 1012,
        left: 206,
    },
    "2": {
        top: 1478,
        left: 206,
    },
}

export {style_position_description, style_position_sale_label}