import React, {FC} from "react";
import {Box, Typography, Button} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const ErrorModal: FC<any> = ({setErrorModal, errorMessage}) => {

    const handleCloseModal = () => {
        setErrorModal(false);
    };
    return (
        <Box sx={{
            margin: "120px 12px", width: "480px", padding: "16px", backgroundColor: "white", borderRadius: "24px",
            display: "flex", flexDirection: "column", gap: "24px", alignItems: "center", position: "relative"
        }}>
            <Box sx={{position: "absolute", top: 16, right: 16,}}>
                <Button onClick={handleCloseModal}>
                    <CloseIcon />
                </Button>
            </Box>

            <Typography sx={{fontSize: "24px", fontWeight: 600}}>Произошла ошибка</Typography>
            <CloseIcon sx={{fontSize: "144px", color: "red"}}/>
            <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>

                <Typography sx={{fontSize: "16px", fontWeight: 500, color: "red"}}>
                    {errorMessage}
                </Typography>

            </Box>

        </Box>
    )
}

export default ErrorModal;