// eslint-disable-next-line import/prefer-default-export
export const type_style_content_banner = {
    "Стандарт": {
        whiteBack: {
            transform: "rotate(100deg)",
            right: "173px",
            top: "33px",
            width: "702px",
            height: "755px"
        },
        info: {
            left: "240px",
            top: "77px"
        },
        infoDescription: {
            color: "black"
        }
    },
    "Стандарт с отступом": {
        whiteBack: {
            transform: "rotate(144deg)",
            right: "46px",
            top: "6px",
            width: "650px",
            height: "700px"
        },
        info: {
            left: "100px",
            top: "83px"
        },
        infoDescription: {
            color: "white"
        }
    },
    "Стандарт с отступом без фона": {
        whiteBack: {
            display: "none",
            transform: "rotate(-100deg)"
        },
        info: {
            left: "100px",
            top: "83px"
        },
        infoDescription: {
            color: "white"
        }
    },
    "Стандарт без фона": {
        whiteBack: {
            display: "none",
            transform: "rotate(-100deg)"
        },
        info: {
            left: "240px",
            top: "77px"
        },
        infoDescription: {
            color: "white"
        }
    },
}