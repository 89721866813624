import React, {FC} from "react";
import getTemplateComponent from "../../services/getTempleteComponent";

const BannerModal: FC<any> = ({availableData}: any) => {
    const TemplateBannerComponent = getTemplateComponent({...availableData })
    return (
        <TemplateBannerComponent availableData={{...availableData}}/>
    )
}

export default BannerModal;