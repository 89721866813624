import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

const DoubleSaleBlock1080_1920: FC<{price: string, discounted_price: string}> = ({price, discounted_price}) => (
    <Box sx={{
        width: "fit-content",
        minWidth: "340px",
        minHeight: "198px",
        padding: "17px",
        display: "flex",
        flexDirection: "column",
        gap: "17px",
        backgroundColor: "#FCE300",
        zIndex: 1
    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "23px",
        }}>
            <Box>
                <Typography sx={{fontSize: "31px", lineHeight: "29px" ,fontWeight: 700}}>баға</Typography>
                <Typography sx={{fontSize: "31px", lineHeight: "29px" ,fontWeight: 700}}>цена:</Typography>
            </Box>
            <Box sx={{
                width: "195px",
                height: 41,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
            }}>
                <Typography sx={{fontSize: "62px", lineHeight: "78px" ,fontWeight: 700}}>{price}₸</Typography>
                <Box sx={{
                    width: "100%",
                    height: "5px",
                    backgroundColor: "#F5146E",
                    position: "absolute",
                    transform: "rotate(168deg)"
                }}/>
            </Box>
        </Box>
        <Typography sx={{height: 86, textAlign: "center" ,fontSize: "121px", lineHeight: "95px" ,fontWeight: 700}}>{discounted_price}₸</Typography>
    </Box>
)


export default DoubleSaleBlock1080_1920;